import { useAuthStore } from "~/stores/useAuthStore";

export default defineNuxtRouteMiddleware((to) => {
  const {isAuthenticated} = useAuthStore(); // make authenticated state reactive

  if (toValue(isAuthenticated) && to?.name === 'login') {
    return navigateTo('/');
  }

  if (!toValue(isAuthenticated) && to?.name !== 'login') {
    abortNavigation();
    return navigateTo('/login');
  }
});
